<template lang="pug">
.main-wrapper.caixas-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Caixas</b>

        Dialog.dialogApagar(header='Remover Plano de Contas' :visible.sync='dialogApagar' :modal='true')
            p(style='font-weigth: bold; text-align: center') Deseja remover conta <b>{{ dialogApagar_data.ds_descricao }}</b>?<br/>
                em deletar uma conta deletará todas as contas associados
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')
        
        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid.p-align-end
                .p-col-12.p-md-4
                    label.form-label Conta:
                    .p-inputgroup
                        InputText(
                            placeholder='Descrição do plano'
                            @keyup.enter.native='applyFilters()'
                            @input='(value) => { if (!value) applyFilters() }'
                            v-model='filters.ds_descricao'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Tipo de Conta:
                    Dropdown(
                        placeholder='Selecione tipo de plano...'
                        :options='options.ie_tipo'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.ie_tipo'
                        showClear
                        filter
                    )
                .p-col-12.p-md-4
                    label.form-label Natureza:
                    Dropdown(
                        placeholder='Selecione a natureza do plano...'
                        :options='options.ie_natureza'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.ie_natureza'
                        showClear
                        filter
                    )
                .p-col-12.p-md-4
                    label.form-label Código:
                    .p-inputgroup
                        InputText(
                            placeholder='Código'
                            @keyup.enter.native='applyFilters()'
                            @input='(value) => { if (!value) applyFilters() }'
                            v-model='filters.ds_codigo'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-4
                    label.form-label Código Resumido:
                    .p-inputgroup
                        InputText(
                            placeholder='Código Resumido'
                            @keyup.enter.native='applyFilters()'
                            @input='(value) => { if (!value) applyFilters() }'
                            v-model='filters.ds_codigo_resumido'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                .p-col-12.p-md-4
                
                .p-col-12.p-md-4
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')

        .ta-right.mt-4
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/plano-de-contas/salvar/0/')")
            br
            br

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
           
            Panel.datatable(header='Lista de Contas')
                DataTable(:value="list")
                    Column(headerStyle='width: 15%;' field='ds_codigo' header='Código' )
                    Column(headerStyle='width: 15%;' field='ds_codigo_resumido' header='Cód. Resumido' )
                    Column(headerStyle='width: 45%;' field='ds_descricao' header='Descrição' )
                    Column(headerStyle='width: 45%;' header='Tipo' )
                        template(#body='props')
                            p(v-if='props.data.ie_tipo' style='text-align: center') {{options.ie_tipo.find(i => i.value == props.data.ie_tipo)?.label}}
                            p(v-else) -
                    Column(headerStyle='width: 15%;' header='Natureza' )
                        template(#body='props')
                            p(v-if='props.data.ie_natureza' style='text-align: center') {{options.ie_natureza.find(i => i.value == props.data.ie_natureza)?.label}}
                            p(v-else) -
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/plano-de-contas/salvar/${ props.data.id }/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .caixas-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 30px;
                height: auto;
            }
        }
        .waitingStatus{
            width: 29px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import MultiSelect from 'primevue/multiselect'

    import { PlanoContas, DominioValor } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'plano-de-contas') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created () {  this.getDominios() },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, MultiSelect, ProgressSpinner, InputText, Dropdown},
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingUnidades: false,
                dialogApagar: false,
                dialogApagar_data: {},
                waitingStatus: false,
                loading: {
                    setor: false,
                    sala: false,
                },
                filters: {
                    ds_descricao: '',
                    ie_tipo: null,
                    ie_natureza: null,
                    ds_codigo: null,
                    ds_codigo_resumido: null
                  
				},
                options:{
                    ie_natureza: [],
                    ie_tipo: [],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            findLabel(ie_tipo) {
                let found = this.options.ie_tipo.find(j => j.value == ie_tipo);
                return found ? found.label : '';
            },
            resetFilters() {
				this.filters.ie_natureza = null
				this.filters.ds_descricao = ''
				this.filters.ie_tipo = null
			},
            limparFiltros() {
				this.resetFilters()
				this.applyFilters()
			},
            alterarStatus (id) {
				let plano = this.list.find(a => a.id === id)
				this.waitingStatus = id
				PlanoContas.save({ id, ie_status: ! plano.ie_status }).then(response => {
					this.waitingStatus = 0
					if (([200, 201, 204]).includes(response.status)) {
						plano.ie_status = ! plano.ie_status
						if (plano.ie_status)
							this.$toast.success('Plano habilitado', { duration: 3000 })
						else
							this.$toast.info('Plano desabilitado', { duration: 3000 })
					} else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
				})
			},
            getList (params) {
                this.waiting = true
                return PlanoContas.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            getDominios() {
                DominioValor.findAll({ds_mnemonico: ['TIPO_PLANO_CONTAS', 'TIPO_NATUREZA']}).then(response => {
                if (response.status == 200){
                    response.data['TIPO_PLANO_CONTAS'].valores.forEach((un) => {
                            this.options.ie_tipo.push({ value: un.ie_valor, label: un.ds_valor})
                        });
                    }  
                    response.data['TIPO_NATUREZA'].valores.forEach((un) => {
                         this.options.ie_natureza.push({ value: un.ie_valor, label: un.ds_valor})
                    });
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                PlanoContas.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Conta removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>